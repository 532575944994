import React from "react";
import whatsapp from "../assets/image/whatsapp1.png";

const WhatsappButton = () => {
  return (
    <div className="whatsapp-ctn">
      <a
        aria-label="Chat on WhatsApp"
        rel="noreferrer"
        target="_blank"
        href="https://wa.me/+9779810101016?text=Hello%2C%20I%20would%20like%20to%20chat%20with%20you"
      >
        {" "}
        <img
          style={{ width: "55px", borderRadius: "100%" }}
          alt="sas"
          src={whatsapp}
        />
      </a>
    </div>
  );
};

export default WhatsappButton;
