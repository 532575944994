import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import service1 from "../assets/image/dispatch-app.jpg";
import service2 from "../assets/image/passenger-app.jpg";
import service3 from "../assets/image/driver-app.jpg";
import playIcon from "../assets/image/play-circle.svg";
import passengerAppVideo from "../assets/videos/ATOZ_DISPATCH_SYSTEM1.mp4";
import driverAppVideo from "../assets/videos/ATOZ_DRIVER.mp4";
import adminVideo from "../assets/videos/ATOZ_DISPATCH_SYSTEM2.mp4";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import appStoreLogo from "../assets/image/App_Store_Icon.webp";
import googlePlayLogo from "../assets/image/Google_Play_Store_icon.png";
import { SlGlobe } from "react-icons/sl";

const Services = () => {
  const [show, setShow] = useState(false);
  const [path, setPath] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showModal = (link) => {
    handleShow();
    setPath(link);
  };

  const servicesData = [
    {
      num: "1.",
      title: "Web Booker System",
      image: service1,
      description:
        "Your control center, allowing you to manage bookings, drivers, and passengers all in one place.Operator receive the booking, confirm the booking and assign the booking to driver.",
      link: adminVideo,
    },
    {
      num: "2.",
      title: "Passenger App",
      image: service2,
      description:
        "Passenger can make booking from app, web or call operator to make a booking.",
      link: passengerAppVideo,
    },
    {
      num: "3.",
      title: "Driver App",
      image: service3,
      description:
        "Empowering your drivers with real-time job notifications and seamless job management.Driver can accept or reject the job.",
      link: driverAppVideo,
    },
  ];

  return (
    <>
      <div className="services">
        <Container>
          <h3>Our Dispatch System Includes</h3>
          {servicesData.length > 0
            ? servicesData.map((item, id) => {
                return (
                  <>
                    <Row key={id}>
                      <Col md={6} className="service-info">
                        <span> {item.num} </span>
                        <h2> {item?.title} </h2>
                        <p>{item.description}</p>
                        <div>
                          {item.link != "" ? (
                            <button
                              className="watch-video-btn"
                              onClick={() => showModal(item.link)}
                            >
                              <img src={playIcon} alt="play icon" />
                              <h5>Watch Video</h5>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>

                        {item?.num !== "1." ? (
                          <div className="download-section">
                            <Link
                              target="_blank"
                              to={
                                "https://apps.apple.com/us/app/a-to-z-dispatch/id6639615450"
                              }
                            >
                              <img
                                style={{
                                  height: "55px",
                                  width: "170px",
                                  objectFit: "contain",
                                }}
                                width={200}
                                src={appStoreLogo}
                                alt="app-store"
                              />
                            </Link>
                            <Link
                              target="_blank"
                              to={
                                "https://play.google.com/store/apps/details?id=com.a2z.dispatch"
                              }
                            >
                              <img
                                style={{
                                  objectFit: "contain",
                                  width: "170px",
                                  height: "55px",
                                }}
                                width={200}
                                src={googlePlayLogo}
                                alt="google-play"
                              />
                            </Link>
                          </div>
                        ) : (
                          <div className="download-section">
                            <Link
                              style={{ textDecoration: "none" }}
                              target="_blank"
                              to={"https://yourcompany.atozdispatch.com"}
                            >
                              <button
                                style={{
                                  background: "#070605",
                                  color: "#fff",
                                  border: "none",
                                  padding: "10px 20px",
                                  borderRadius: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "5px",
                                  gap: "5px",
                                }}
                              >
                                <SlGlobe size={20} /> Web Version Demo
                              </button>
                            </Link>
                          </div>
                        )}
                      </Col>
                      <Col md={6}>
                        <img src={item.image} alt="image" />
                      </Col>
                    </Row>
                  </>
                );
              })
            : ""}
        </Container>
      </div>
      <Modal show={show} onHide={handleClose} className="full-modal">
        <Button variant="secondary" onClick={handleClose}>
          X
        </Button>
        <video width="100%" height="100%" controls={true} autoPlay="true">
          <source src={path} type="video/mp4" />
        </video>
      </Modal>
    </>
  );
};
export default Services;
