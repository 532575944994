import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Avatar, Empty, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../Helpers/APIHelper";
import Header from "../../components/header";
import NewFooter from "../../components/NewFooter";

const BlogPage = (props) => {
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const res = await getRequest("blogs");
      setBlogData(res?.data || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header title={props.metaTitle} desc={props.metaContent} />
      <section style={{ background: "#f7f7f8" }} className="blog-section">
        <Container>
          <div className="blog-layout">
            <div className="left-layout">
              {loading ? (
                // Loading skeletons
                Array.from({ length: 5 }).map((_, index) => (
                  <Skeleton.Node
                    key={index}
                    active
                    style={{
                      width: 600,
                      height: 150,
                      marginBottom: 16,
                    }}
                  />
                ))
              ) : blogData.length > 0 ? (
                blogData.map((item, ind) => (
                  <div
                    key={ind}
                    onClick={() => navigate(`/blog/${item?.slug}`)}
                    className="blog-container"
                  >
                    <div className="img-ctn">
                      {item?.image_url ? (
                        <img
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                          }}
                          src={item?.image_url}
                          alt="blog-img"
                        />
                      ) : (
                        <Skeleton.Image
                          style={{ width: "200px", minHeight: "170px" }}
                        />
                      )}
                    </div>
                    <div className="content-ctn">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          size="small"
                          style={{
                            backgroundColor: "#fde3cf",
                            color: "#f56a00",
                            marginRight: "10px",
                          }}
                        >
                          {item?.posted_by?.toLowerCase().slice(0, 2)}
                        </Avatar>
                        <p>{item?.posted_by}</p>
                      </div>
                      <label className="b-title">{item?.title}</label>
                      <div style={{ maxHeight: "200px", overflowY: "hidden" }}>
                        <p
                          className="short-desc truncate"
                          dangerouslySetInnerHTML={{
                            __html: item?.short_description,
                          }}
                        />
                      </div>
                      <p className="chips-card" style={{ fontSize: "12px" }}>
                        {item?.posted_at}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <Empty description="No blogs available" />
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
      <NewFooter />
    </>
  );
};

export default BlogPage;
