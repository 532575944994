import React, { useState } from "react";
import { Container } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { BsGlobeEuropeAfrica } from "react-icons/bs";
import { postRequest } from "../../Helpers/APIHelper";
import { CircularProgress } from "@material-ui/core";

const NewContactUs = () => {
  const [captchaValue, setCaptchaValue] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!captchaValue) {
      alert("Please verify that you are not a robot.");
      return;
    }
    setBtnDisable(true);
    postRequest("send-contact-us", formData)
      .then((response) => {
        setBtnDisable(false);
        if (response.status) {
          alert(response?.message ?? "");
          window.location.reload();
        } else {
          if (response?.errors ?? false) {
            alert(response?.message ?? "");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };

  return (
    <div style={{ background: "#EFF2F7" }}>
      <Container>
        <div className="contact-form">
          <h3>Contact Us</h3>
          <div className="form-container">
            <iframe
              title="iframe-contact"
              width="3000"
              height="900"
              src="https://654ad6eb.sibforms.com/serve/MUIFACw-BzdAwiFzTQP-ZTKUgltcUFSqq3-QURzq0LUZNpa9kP4-CbnQbsOdvz1h9nMGHR3ifIS0YJcy7kKwkSoxT451PmR9FKeDqR_osmyCMooKiXPV5H7xgV1iYZ3blJGwiaujvaIL9hDTjI_5yCtiBMg763AMauBZEg9wpjoxQaGU1IYdu2mIIZzr4iMgxAmvNoSyy_ArGMvq "
            ></iframe>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NewContactUs;
