import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";

import { Collapse } from "antd";
import CustomCollapse from "./collapse-component/CustomCollapse";
import { useNavigate } from "react-router-dom";

const FAQ = () => {
  const navigate = useNavigate();
  const contactData = [
    // {
    //   image: phone,
    //   title: "call us",
    //   url: "tel: 9779810101016",
    //   contactInfo: "+ 977 9810101016",
    // },
    // {
    //   image: mail,
    //   title: "mail at",
    //   url: "mailto: support@urride.online",
    //   contactInfo: "support@urride.online",
    // },
    // {
    //   image: whatsapp,
    //   title: "whatsapp",
    //   url: "https://wa.me/9779810101016",
    //   contactInfo: "+ 977 9810101016",
    // },
  ];

  const items = [
    {
      key: "1",
      label: "This is panel header 1",
      children: <p>sdsdsds</p>,
    },
    {
      key: "2",
      label: "This is panel header 2",
      children: <p>sdsdsds</p>,
    },
    {
      key: "3",
      label: "This is panel header 3",
      children: <p>sssss</p>,
    },
  ];

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <>
      <div className="contact">
        <Container>
          <Row>
            <Col md={5}>
              <div className="contact-left">
                <p className="p1">Support</p>
                <h2 className="h2">FAQs</h2>
                <p className="p2">
                  Everything you need to know about the plans and billing. Can't
                  find the answer you're looking for?
                </p>
                <p className="p3">Please Contact Us</p>
              </div>
            </Col>
            <Col md={7}>
              <CustomCollapse />{" "}
              <button
                onClick={() => navigate("/faq")}
                style={{
                  padding: "10px 7px ",
                  boxShadow: "2px 3px 6px rgba(0, 0, 0, 0.3)",
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
                className="header-outlined-btn"
              >
                View More
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default FAQ;
