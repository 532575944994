import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { DatePicker, Spin } from "antd";
import UploadButton from "./UploadButton";
import AgrementModal from "../../components/modal/AgrementModal";
import ThankYouModal from "../../components/modal/ThankYouModal";
import { Link } from "react-router-dom";
import { PostMethod } from "../../Helpers/PostMethod";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const SignupForm = () => {
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [openThankYou, setOpenThankYou] = useState(false);
  const [signatureImg, setSignatureImg] = useState(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    business_name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    mobile: "",
    plan: "Standard",
    card_number: "",
    card_expiry: null,
    card_holder_name: "",
    card_verification_code: "",
    signature: "",
    business_logo: [],
  });

  const [agreementInfo, setAgreementInfo] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    plan: "",
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleThankClose = () => {
    setOpenThankYou(false);
  };
  const handleThankOpen = () => {
    setOpenThankYou(true);
  };

  const defaultValues = {
    name: "",
    email: "",
    business_name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    mobile: "",
    plan: "Standard",
    card_number: "",
    card_expiry: null,
    card_holder_name: "",
    card_verification_code: "",
    signature: "",
    business_logo: [],
  };

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  useEffect(() => {
    setValue("plan", "Standard");
  }, [setValue]);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onSubmit = async (data) => {
    setError("");
    try {
      data = {
        ...data,
        signature: signatureImg && signatureImg,
        ...(data?.domain_name != "" && { domain_name: data?.domain_name }),
      };
      setFormData(data);
      setAgreementInfo({
        name: data?.name,
        phone: data?.mobile,
        email: data?.email,
        address: data?.country,
        city: data?.city,
        state: data?.state,
        country: data?.country,
        plan: data?.plan,
      });
      if (isAgreed === false) {
        setOpen(true);
        return;
      }
      setIsPending(true);
      const formData = new FormData();
      for (const key in data) {
        if (data[key] !== null && data[key] !== undefined) {
          formData.append(key, data[key]);
        }
      }
      const response = await PostMethod(
        "https://payment.atozdispatch.com/api/register",
        formData
      );
      setIsPending(false);
      setOpenThankYou(true);
    } catch (error) {
      console.log(error);
      console.log(error.response?.data?.message);
      setError(error.response?.data?.message);
      setIsPending(false);
    }
  };

  const handleFinalSubmit = async (data) => {
    try {
      setIsPending(true);
      const dataToSend = new FormData();
      for (const key in data) {
        if (data[key] !== null && data[key] !== undefined) {
          dataToSend.append(key, data[key]);
        }
      }
      const response = await PostMethod(
        "https://payment.atozdispatch.com/api/register",
        dataToSend
      );
      setIsPending(false);
      setOpenThankYou(true);
    } catch (error) {
      console.log(error);
      setError(error.response?.data?.message);
      setIsPending(false);
    }
  };

  return (
    <div className="signupform-wrapper">
      <div className="custom-alert">
        <h3>30 DAYS FREE TRIAL</h3>
        <p>* You won't be charged Until Trial Period</p>
      </div>
      <form className="input-group-form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="name">Name</label>
          <Controller
            name="name"
            id="name"
            control={control}
            rules={{ required: "Full Name is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.name ? "1px solid rgba(255, 0, 0, 0.712)" : "",
                }}
                type="text"
                placeholder="Name"
                {...field}
              />
            )}
          />
          {errors.name && (
            <p className="error-message">*{errors.name.message}</p>
          )}
        </div>
        {/* 
        <div>
          <label htmlFor="mobile">Phone</label>
          <Controller
            name="mobile"
            control={control}
            rules={{ required: "Phone Number is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.mobile
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="tel"
                placeholder="Phone Number"
                {...field}
              />
            )}
          />
          {errors.mobile && (
            <p className="error-message">*{errors.mobile.message}</p>
          )}
        </div> */}
        <div>
          <label htmlFor="mobile">Phone</label>
          <Controller
            name="mobile"
            control={control}
            rules={{ required: "Phone Number is required" }}
            render={({ field }) => (
              <PhoneInput
                {...field}
                country={"us"}
                value={field.value}
                onChange={(phone) => field.onChange("+" + phone)}
                className={` w-full ${
                  errors.phone
                    ? "border-red-500 border-[2px]"
                    : "border-[#cccccc] border-[1px]"
                } bg-light_gray mt-[5px] rounded-[4px]`}
              />
            )}
          />
          {errors.mobile && (
            <p className="error-message">*{errors.mobile.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="email">Email</label>
          <Controller
            name="email"
            control={control}
            rules={{ required: "Email is required" }}
            render={({ field }) => (
              <input
                type="email"
                style={{
                  border: errors.email
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                placeholder="johndoe@example.com"
                {...field}
              />
            )}
          />
          {errors.email && (
            <p className="error-message">*{errors.email.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="business_name">Business Name</label>
          <Controller
            name="business_name"
            control={control}
            rules={{ required: "Business Name is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.business_name
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="ABC Company"
                {...field}
              />
            )}
          />
          {errors.business_name && (
            <p className="error-message">*{errors.business_name.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="business_address">Address</label>
          <Controller
            name="address"
            control={control}
            rules={{ required: "Business Address is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.address
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="Business Address"
                {...field}
              />
            )}
          />
          {errors.address && (
            <p className="error-message">*{errors.address.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="business_name">City</label>
          <Controller
            name="city"
            control={control}
            rules={{ required: "City is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.city ? "1px solid rgba(255, 0, 0, 0.712)" : "",
                }}
                type="text"
                placeholder="city"
                {...field}
              />
            )}
          />
          {errors.city && (
            <p className="error-message">*{errors.city.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="business_name">State</label>
          <Controller
            name="state"
            control={control}
            rules={{ required: "State is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.state
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="State"
                {...field}
              />
            )}
          />
          {errors.state && (
            <p className="error-message">*{errors.state.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="country">Country</label>
          <Controller
            name="country"
            control={control}
            rules={{ required: "Country is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.country
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="Country"
                {...field}
              />
            )}
          />
          {errors.country && (
            <p className="error-message">*{errors.country.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="domain_name">Domain Name (if any)</label>
          <Controller
            name="domain_name"
            rules={{ required: "Domain is required" }}
            control={control}
            render={({ field }) => (
              <input
                style={{
                  border: errors.domain_name
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="Domain Name"
                {...field}
              />
            )}
          />
          {errors.domain_name && (
            <p className="error-message">*{errors.domain_name.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="business_logo">Business Logo</label>
          <Controller
            name="business_logo"
            control={control}
            render={({ field }) => <UploadButton {...field} />}
          />
          {errors.business_logo && (
            <p className="error-message">*{errors.business_logo.message}</p>
          )}
        </div>

        <div className="package-plan">
          <h2>Choose Your Package</h2>
          <Controller
            name="plan"
            control={control}
            rules={{ required: "Please select a package plan" }}
            render={({ field }) => (
              <div className="plan1-ctn">
                <div
                  className="card"
                  onClick={() => field.onChange("Basic")}
                  style={{
                    border:
                      field.value === "Basic"
                        ? "2px solid green"
                        : "1px solid #ccc",
                  }}
                >
                  <input
                    type="radio"
                    value="Basic"
                    checked={field.value === "Basic"}
                    onChange={() => field.onChange("Basic")}
                    style={{ display: "none" }}
                  />
                  <label style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Basic
                  </label>
                  <label style={{ fontSize: "22px", fontWeight: "800" }}>
                    $99
                  </label>
                </div>
                <div
                  className="card"
                  onClick={() => field.onChange("Standard")}
                  style={{
                    border:
                      field.value === "Standard"
                        ? "2px solid green"
                        : "1px solid #ccc",
                  }}
                >
                  <input
                    type="radio"
                    value="Standard"
                    checked={field.value === "Standard"}
                    onChange={() => field.onChange("Standard")}
                    style={{ display: "none" }}
                  />
                  <label style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Standard
                  </label>
                  <label style={{ fontSize: "22px", fontWeight: "800" }}>
                    $149
                  </label>
                </div>
                <div
                  className="card"
                  onClick={() => field.onChange("Premium")}
                  style={{
                    border:
                      field.value === "Premium"
                        ? "2px solid green"
                        : "1px solid #ccc",
                  }}
                >
                  <input
                    type="radio"
                    value="Premium"
                    checked={field.value === "Premium"}
                    onChange={() => field.onChange("Premium")}
                    style={{ display: "none" }}
                  />
                  <label style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Premium
                  </label>
                  <label style={{ fontSize: "22px", fontWeight: "800" }}>
                    $199
                  </label>
                </div>
              </div>
            )}
          />
        </div>

        <div>
          <label htmlFor="card_number">Card Number</label>
          <Controller
            name="card_number"
            control={control}
            rules={{ required: "Card Number is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.card_number
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="Enter Card Number"
                {...field}
              />
            )}
          />
          {errors.card_number && (
            <p className="error-message">*{errors.card_number.message}</p>
          )}
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            <label htmlFor="card_expiry">Card Expiry</label>
            <Controller
              name="card_expiry"
              control={control}
              rules={{ required: "Card Expiry is required" }}
              render={({ field }) => (
                <DatePicker
                  onChange={(date, dateString) => {
                    field.onChange(dateString);
                  }}
                  picker="month"
                  format="MM/YYYY"
                  placeholder="MM/YYYY"
                  style={{
                    border: errors.card_expiry
                      ? "1px solid rgba(255, 0, 0, 0.712)"
                      : "",
                    padding: "10px 10px",
                    width: "100%",
                    borderRadius: "12px",
                  }}
                />
              )}
            />
            {/* {errors.card_expiry && (
              <p style={{ marginTop: "-10px" }} className="error-message">
                *{errors.card_expiry.message}
              </p>
            )} */}
          </div>
          <div>
            <label htmlFor="card_verification_code">
              Card Verification Code
            </label>
            <Controller
              name="card_verification_code"
              control={control}
              rules={{ required: "Card Verification Code is required" }}
              render={({ field }) => (
                <input
                  style={{
                    border: errors.card_verification_code
                      ? "1px solid rgba(255, 0, 0, 0.712)"
                      : "",
                  }}
                  type="text"
                  placeholder="CVC"
                  {...field}
                />
              )}
            />
            {errors.card_verification_code && (
              <p className="error-message">
                *{errors.card_verification_code.message}
              </p>
            )}
          </div>
        </div>

        <div>
          <label htmlFor="card_holder_name">Card Holder Name</label>
          <Controller
            name="card_holder_name"
            control={control}
            rules={{ required: "Card Holder Name is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.card_holder_name
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="Card Holder Name"
                {...field}
              />
            )}
          />
          {errors.card_holder_name && (
            <p className="error-message">*{errors.card_holder_name.message}</p>
          )}
        </div>

        <div className="terms">
          <div>
            <input onChange={handleChange} type="checkbox" />
          </div>
          <p>
            I agree to{" "}
            <Link target="_blank" to={"/privacy-policy"}>
              Privacy Policy
            </Link>{" "}
            &{" "}
            <Link target="_blank" to={"/terms-of-service"}>
              Terms
            </Link>
          </p>
        </div>

        {error && (
          <p style={{ color: "red", fontStyle: "italic", fontSize: "15px" }}>
            *{error}
          </p>
        )}

        <button
          style={{
            textAlign: "center",
            justifyContent: "center",
            opacity: !isChecked ? "0.8" : "1",
          }}
          className="header-outlined-btn"
          disabled={!isChecked || isPending}
          type="submit"
        >
          {isPending ? (
            <span>
              <Spin /> Processing...{" "}
            </span>
          ) : (
            "30 Days Free Trial"
          )}
        </button>
      </form>

      <AgrementModal
        agreementInfo={agreementInfo}
        setIsAgreed={setIsAgreed}
        formData={formData}
        setFormData={setFormData}
        isAgreed={isAgreed}
        open={open}
        handleClose={handleClose}
        setSignatureImg={setSignatureImg}
        handleSubmission={handleFinalSubmit}
      />

      <ThankYouModal
        info={agreementInfo}
        open={openThankYou}
        handleClose={handleThankClose}
      />
    </div>
  );
};

export default SignupForm;
