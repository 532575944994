import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfService from "./pages/termsOfService";
import CookiesPolicy from "./pages/cookiesPolicy";
import NotFound from "./pages/NotFound";
import Signup from "./pages/signup/Signup";
import BlogPage from "./pages/blog/BlogPage";
import SingleBlog from "./pages/single-blog/SingleBlog";
import FAQ from "./components/FAQ";
import FaqPage from "./pages/faq/FaqPage";
// import whatsapp from "../assets/image/whatsapp_logo_green.png";
import whatsapp from "../src/assets/image/whatsapp1.png";
import WhatsappButton from "./components/WhatsappButton";

const App = () => {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/terms-of-service"
          element={
            <TermsOfService
              metaTitle="Agreement for Using A to Z Dispatch | Terms and Conditions"
              metaContent="Before using A to Z Dispatch, please read and understand our terms and conditions. Our agreement outlines the rules and regulations for using our platform and creating your Booking App and website."
            />
          }
        />

        <Route
          exact
          path="/privacy-policy"
          element={
            <PrivacyPolicy
              metaTitle="How We Protect Your Privacy on A to Z Dispatch"
              metaContent="At A to Z Dispatch, we are committed to protecting your privacy. Our privacy policy outlines how we collect, use, and protect your personal information when you use our platform to create your Booking App and website."
            />
          }
        />

        <Route
          exact
          path="/cookies-policy"
          element={
            <CookiesPolicy
              metaTitle="Understanding Cookies on A to Z Dispatch"
              metaContent="Our website builder uses cookies to enhance your browsing experience. Learn more about our cookies policy and how we use cookies to improve our Booking App and website functionality."
            />
          }
        />

        <Route
          exact
          path="/blog"
          element={
            <BlogPage
              metaTitle="White Label Limo Software - A to Z Dispatch Blog and Updates"
              metaContent="Best Chauffeur Dispatch System — Best chauffeur application development company. how much does a limo dispatch system cost?"
            />
          }
        />

        <Route exact path="/faq" element={<FaqPage />} />

        <Route exact path="/blog/:slug" element={<SingleBlog />} />

        <Route
          exact
          path="/"
          element={
            <Index
              metaTitle="Best Limo Reservation Software and App - Try All-in-One Limo App | 30 Days Free Trial"
              metaContent="Elevate your limo business online effortlessly with A to Z Dispatch. Fast, easy, and professional. Start now with 30 days Free Trial. Maximize revenue with All-in-One Limo Reservation Software with App. IOS & Android ready-to-use. Book demo now"
            />
          }
        />
        <Route
          exact
          path="/signup"
          element={
            <Signup
              metaTitle="A to Z Dispatch Pricing | Limo Dispatch Software Pricing"
              metaContent="Starter: $99/month Standard: $149/month Pro: $199/month upfront payment for all plans. 30 Days."
            />
          }
        />

        <Route
          path="*"
          element={
            <NotFound
              metaTitle="Limo & chauffeur  Software | Not Found"
              metaContent=""
            />
          }
        />
      </Routes>
      <WhatsappButton />
    </>
  );
};
export default App;
